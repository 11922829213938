import {Controller} from "stimulus";

export default class extends Controller {
  static targets = ["control", "entries"];

  currentControllerDOMFilter= null

  initialize() {
    if (this.hasControlTarget){
      let myClasses = [...this.element.classList].map(c => `.${c}`).join('')
      this.currentControllerDOMFilter = `[data-controller="pagination"]${myClasses}`

      if('IntersectionObserver' in window){
        const observerOptions = { rootMargin: "200px 0px 0px 0px" };
        const observer = new IntersectionObserver(this.handleObservation.bind(this), observerOptions);
        observer.observe(this.controlTarget);
      } else {
        this.controlTarget.firstElementChild.style.display = 'block';
      }
    }
  }

  async handleObservation(entries) {
    if (entries.find((entry) => entry.isIntersecting)) {
      let url = this.controlTarget.querySelector("a[rel='next']")?.href;
      if (!url) {
        return;
      }
      try {
        const response = await fetch(url);
        const html = await response.text();
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        this.entriesTarget.insertAdjacentHTML('beforeend', doc.querySelector(`${this.currentControllerDOMFilter} [data-pagination-target="entries"]`).innerHTML);
        this.controlTarget.innerHTML = doc.querySelector(`${this.currentControllerDOMFilter} [data-pagination-target="control"]`).innerHTML;
      } catch (e) {
        console.warn(e)
      }
    }
  }
}
