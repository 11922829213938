// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

//import Rails from "@rails/ujs"
//import Turbolinks from "turbolinks"
//import * as ActiveStorage from "@rails/activestorage"
//import "channels"

//Rails.start()
//Turbolinks.start()
//ActiveStorage.start()

import "@fontsource/inter/latin-400.css";
import "@fontsource/inter/latin-600.css";
import "@fontsource/inter/latin-700.css";

import "controllers"

import {createApp} from 'vue'
import { createI18n } from 'vue-i18n'

import ProductFilterApp from '../product_filter_app'

document.addEventListener('DOMContentLoaded', () => {
  //let el = document.createElement("div");
  //document.body.appendChild(el)

  let el = document.getElementById('product-filter-app')
  if (el){
    const i18n = createI18n({
      locale: el.dataset.locale,
      fallbackLocale: 'en',
      messages: {
        en: {
          reset: "reset",
          search: "Search",
          filter: "Filter",
          filters: {
            price_range: {
              name: "Price"
            },
            brand: {
              name: "Brand"
            },
            category: {
              name: "Model"
            },
            color: {
              name: "Color"
            },
            size: {
              name: "Size"
            },
            on_sale: {
              name: "On Sale?"
            }
          },
          from: "from",
          to: "to",
          submit: "Show Results",
          checkbox_filter: {
            values: {
              true: 'Yes',
              null: ''
            }
          }
        },
        de: {
          reset: "zurücksetzen",
          search: "Suche",
          filter: "Filter",
          filters: {
            price_range: {
              name: "Preis"
            },
            brand: {
              name: "Brand"
            },
            category: {
              name: "Modell"
            },
            color: {
              name: "Farbe"
            },
            size: {
              name: "Größe"
            },
            on_sale: {
              name: "Im Sale?"
            }
          },
          from: "ab",
          to: "bis",
          submit: "Ergebnisse anzeigen",
          checkbox_filter: {
            values: {
              true: 'Ja',
              null: ''
            }
          }
        }
      }
    })
    createApp(ProductFilterApp, { categoriesUrl: el.dataset.categoriesUrl, colors: JSON.parse(el.dataset.colors), sizes: JSON.parse(el.dataset.sizes), skipFilters: JSON.parse(el.dataset.skipFilters)}).use(i18n).mount(el)
  }
})

