import {Controller} from "stimulus";

export default class extends Controller {
  static targets = [
    "sorting",
  ];

  openDialog() {
    this.element.classList.add("sidebar-opened")
  }

  closeDialog() {
    this.element.classList.remove("sidebar-opened")
  }

  changeSorting() {
    window.location.href = this.sortingTarget.value;
  }
}