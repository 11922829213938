import {Controller} from "stimulus";

export default class extends Controller {
  static targets = ['template', 'drain'];

  reveal(event){
    if(this.hasTemplateTarget){
      this.drainTarget.append(this.templateTarget.content)
      this.templateTarget.remove();
    }
  }
}
