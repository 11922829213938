import {Controller} from "stimulus";

export default class extends Controller {
  static values = {activeNavigationFacet: Object, navigationOpen: Boolean, searchOpen: Boolean};
  static targets = ["sheet", "mobile", "sneaker", "news", "collabs", "streetwear", "staticPages", "closeButton", "backText", "backButton", "backIcon"];

  toggleMobileSearch() {
    if (this.searchOpen){
      this.closeSearch()
    }else{
      this.openSearch()
    }
  }

  toggleNavigationFacet(event) {
    let target = this[`${event.params.elementId}Target`]

    if (this.navigationOpen && this.activeNavigationFacet === target){
      this.closeNavigation()
    }else{
      this.openNavigation(target)
    }
  }

  showNavigationFacet(event) {
    let target = this[`${event.params.elementId}Target`]

    this.openNavigation(target)
  }

  // @see https://stimulus.hotwired.dev/reference/targets
  // Since there are no dynamic targets in stimulus, we need to hide/show these in Vanilla JS

  hideOverlayContent(facet){
    facet.classList.remove('active')
  }
  showOverlayContent(facet) {
    if (this.activeNavigationFacet && this.activeNavigationFacet !== facet){
      this.hideOverlayContent(this.activeNavigationFacet)
    }
    facet.classList.add('active')
    this.activeNavigationFacet = facet
  }

  openNavigation(facet){
    if (this.searchOpen){
      this.closeSearch()
    }
    this.element.classList.add('navigation-opened')
    this.navigationOpen = true
    this.showOverlayContent(facet);
  }
  closeNavigation() {
    this.element.classList.remove('navigation-opened')
    this.hideOverlayContent(this.activeNavigationFacet)
    this.navigationOpen = false
  }

  openSearch(){
    if (this.navigationOpen){
      this.closeNavigation()
    }
    this.element.classList.add('search-opened')
    this.searchOpen = true
    //this.showOverlayContent(facet);
  }
  closeSearch() {
    this.element.classList.remove('search-opened')
    this.searchOpen = false
  }
}
