import {Controller} from "stimulus";

export default class extends Controller {
  static targets = ['content', 'link'];

  initialize() {
    if(!this.isClamped()) {
      this.show();
    }
  }

  isClamped() {
    return this.contentTarget.scrollHeight > this.contentTarget.clientHeight
  }

  show() {
    this.contentTarget.classList.remove('shortened');
    this.linkTarget.style.display = 'none';
  }
}
