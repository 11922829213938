import {Controller} from "stimulus";

export default class extends Controller {
  static targets = ['rangeFrom', 'rangeTo', 'valueFrom', 'valueTo'];

  initialize() {
    if (this.hasValueToTarget) {
      this.valueToTarget.innerHTML = this.rangeToTarget.value;
    }
    if (this.hasValueFromTarget) {
      this.valueFromTarget.innerHTML = this.rangeFromTarget.value;
    }
  }

  updateFromValue() {
    let fromValue = this.rangeFromTarget.value;
    let toValue = this.rangeToTarget.value;

    if (fromValue >= toValue) {
      this.rangeToTarget.value = fromValue;
      this.valueToTarget.innerHTML = fromValue;
    }

    this.valueFromTarget.innerHTML = fromValue;
  }

  updateToValue() {
    let fromValue = this.rangeFromTarget.value;
    let toValue = this.rangeToTarget.value;

    if (toValue <= fromValue) {
      this.rangeFromTarget.value = toValue;
      this.valueFromTarget.innerHTML = toValue;
    }
    this.valueToTarget.innerHTML = toValue;
  }
}
