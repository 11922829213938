<template>
  <section class="main-screen"
       v-if="selectedFilter === null">
    <div class="products__filter__element">
      <span class="text-bold icon-inline filter__close" data-action="click->product-filter#closeDialog">
        <i class="icon icon-arrow_left"></i>
        {{ $t("filter") }}
      </span>
      <span @click="resetAllFilters">{{ $t("reset") }}</span>
    </div>

    <div class="products__filter__element"
         v-for="filter in availableFilters">
      <div class="products__filter__element__link" @click="selectFilter(filter)">
        <span class="text-bold">{{$t(`filters.${filter.id}.name`)}}</span>
        <i class="icon icon-arrow_right"></i>
      </div>
      <div v-if="Array.isArray(filter.value)">
        <span v-if="filter.value[0] !== null"><small>{{ $t("from") }}</small> {{filterValueFor(filter.value[0])}} €</span>
        <span v-if="filter.value[1] !== null"><small>{{ $t("to") }}</small> {{filterValueFor(filter.value[1])}} €</span>
      </div>
      <div v-else-if="filter.picker.type === 'checkbox'">
        <span>{{$t(`checkbox_filter.values.${filter.value}`)}}</span>
      </div>
      <div v-else>
        <span>{{filterValueFor(filter.value)}}</span>
      </div>
    </div>

    <input type="submit" class="products__filter__submit" @click.prevent="submitFilters" :value="$t('submit')"/>
  </section>

  <section class="detail-screen filter__facet"
       v-else>
    <div class="products__filter__element">
          <span class="text-bold icon-inline"
                @click="selectFilter(null)">
            <i class="icon icon-arrow_left"></i>
            {{$t(`filters.${selectedFilter.id}.name`)}}
          </span>
      <span class="text-bold" @click="resetFilter(selectedFilter)">{{ $t("reset") }}</span>
    </div>

    <template v-if="selectedFilter.picker.type === 'range'">
      <div class="products__filter__element grid-like">
          <!--      <p class="text-bold">{{selectedFilter.id}}</p>-->
        <label>
          <span>{{$t("from")}}</span>
          <input type="number" v-model="selectedFilter.value[0]">
          <span>€</span>
        </label>
        <label>
          <span>{{ $t("to") }}</span>
          <input type="number" v-model="selectedFilter.value[1]">
          <span>€</span>
        </label>
      </div>
    </template>

    <template v-if="selectedFilter.picker.type === 'checkbox'">
      <div class="products__filter__element grid-like">
        <label>
          <span style="flex-grow: 1">{{$t(`filters.${selectedFilter.id}.name`)}}</span>
          <input style="width: inherit" type="checkbox" @change="$event.target.checked === false ? selectedFilter.value = null : selectedFilter.value = true" :checked="selectedFilter.value === true">
        </label>
      </div>
    </template>

    <template v-else-if="selectedFilter.picker.type === 'collection'">
      <template v-if="selectedFilter.picker.config.search === true">
        <div class="search">
          <input type="search" :placeholder="$t('search')" v-model="selectedFilter.picker.searchFilter" />
        </div>
      </template>
      <div v-for="item in filteredCollection(selectedFilter)"
           :class="{products__filter__element: true, centered: true, selected: selectedFilter.value === item}"
           @click="selectedFilter.value = item; selectedFilter = null">
        <img class="icon icon--main" :src="item.icon_image_path" v-if="item.icon_image_path"/>
        <span class="color-icon icon--main" :style="{ 'background-color': item.color_hex }" v-if="item.color_hex"/>
        {{item.name}}
      </div>
    </template>
  </section>
</template>

<script>

function getParamsForUrl(filter){
  let wasArray = false
  let params = []

  // normalize first
  if(Array.isArray(filter.param.name)){
    wasArray = true
    params = filter.param.name.map((name, i) => {
      return [name, filter.param.value[i], filter.value[i]]
    })
  }else if (filter.param.name){
    params = [[filter.param.name, filter.param.value, filter.value]]
  }

  // then process
  let paramValues = params.map(tuple => {
    let [name, valueMethod, value] = tuple

    if(value && valueMethod){
      return {[name]: value[valueMethod]}
    }else{
      return {[name]: value}
    }
  })

  // merge into one object
  return Object.assign(...paramValues)
}

function getValuesFromUrl(filter){
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  let wasArray = false
  let params = []

  // normalize first
  if(Array.isArray(filter.param.name)){
    wasArray = true
    params = filter.param.name.map((name, i) => {
      return [name, filter.param.value[i]]
    })
  }else if (filter.param.name){
    params = [[filter.param.name, filter.param.value]]
  }

  // then process
  let paramValues = params.map(tuple => {
    let [name, valueMethod] = tuple
    const value = urlParams.get(name)

    if(valueMethod){
      if(filter.picker.type === "collection"){
        return filter.picker.config.collection.find(i => {
          if (Number.isInteger(i[valueMethod])){
            return i[valueMethod].toString() === value
          } else {
            return i[valueMethod] === value
          }
        })
      }else{
        console.warn("unsupported picker type detected: ", filter.picker.type, " in ", filter)
      }
    }else{
      if (filter.picker.type === "checkbox") {
        return value === 'true' ? true : null
      } else {
        return value
      }
    }
  })

  // and denormalize & return
  if(wasArray)
    return paramValues
  else
    return paramValues[0]
}

import { get } from '@rails/request.js'

export default {
  components:{
  },
  props: {
    categoriesUrl: String,
    colors: Array,
    sizes: Array,
    skipFilters: Array
  },
  data: function () {
    return {
      selectedFilter: null,
      filters: [
        {
          id: 'price_range',
          type: Number,
          picker: {
            type: "range",
            config: {
              min: 0,
              max: 500
            }
          },
          param:{
            name: ["q[lowest_price_in_eur_gteq]", "q[lowest_price_in_eur_lteq]"],
            value: [null, null]
          },
          value: [null, null]
        },
        {
          id: 'category',
          type: Object,
          picker: {
            type: "collection",
            searchFilter: "",
            config: {
              search: true,
              collection: null
            }
          },
          param: {
            name: "q[categories_id_eq]",
            value: "id"
          },
          value: null
        },
        {
          id: 'color',
          type: Object,
          picker: {
            type: "collection",
            searchFilter: "",
            config: {
              search: true,
              collection: []
            }
          },
          param: {
            name: "q[color_eq]",
            value: "color"
          },
          value: null
        },
        {
          id: 'size',
          type: Object,
          picker: {
            type: "collection",
            searchFilter: "",
            config: {
              search: true,
              collection: []
            }
          },
          param: {
            name: "q[offered_sizes_array_contains]",
            value: "name"
          },
          value: null
        },
        {
          id: 'on_sale',
          type: Object,
          picker: {
            type: "checkbox",
          },
          param: {
            name: "q[on_sale_eq]",
            value: null
          },
          value: null
        }
      ]
    }
  },
  methods: {
    resetAllFilters(){
      this.filters.forEach(f => this.resetFilter(f))
    },
    resetFilter(filter){
      if (Array.isArray(filter.value)){
        filter.value = filter.value.map(e => null)
      }else{
        filter.value = null
      }
      if (filter['picker']['searchFilter']){
        filter.picker.searchFilter = ""
      }
    },
    selectFilter(filter){
      this.selectedFilter = filter
    },
    filterValueFor(value){
      if(value && value.hasOwnProperty('name'))
        return value.name
      else
        return value
    },
    submitFilters(){
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);

      this.filters.forEach(f => {
        let params = getParamsForUrl(f)

        for (const prop in params) {
          if(params[prop]){
            urlParams.set(prop, params[prop])
          }else{
            urlParams.delete(prop)
          }
        }
      })

      const url = window.location.href.split('?')[0]

      window.location.href = url + "?" + urlParams.toString()
    },
    parseFilterValuesFromUrl(){
      this.filters.forEach(f => {
        f.value = getValuesFromUrl(f)
      })
    },

    filteredCollection(filter){
      let search = filter.picker.searchFilter
      if(search === "")
        return filter.picker.config.collection;
      else
        return filter.picker.config.collection.filter(el => {
          return el.name.toLowerCase().indexOf(search.toLowerCase()) >= 0
        })
    }
  },
  computed: {
    availableFilters() {
      return this.filters.filter(f => !this.skipFilters.includes(f.id))
    }
  },

  beforeMount() {
    get(this.categoriesUrl, {responseKind: "json"}).then(r => {
      if(r.ok){
        r.json.then(json => {
          this.filters.find(f => f.id === "category").picker.config.collection = json
          this.parseFilterValuesFromUrl()
        })
      }
    })

    this.filters.find(f => f.id === "color").picker.config.collection = this.colors
    this.filters.find(f => f.id === "size").picker.config.collection = this.sizes.map(s => ({name: s}))
  }
}
</script>
